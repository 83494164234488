<template>
  <div class="home-container-m">
    <div class="inner-home-container">
      <div class="title">
        {{ $t('一站式') }}<span style="color: #2e6dff">{{ $t('全球雇佣') }}</span
        >{{ $t('平台标题') }}
      </div>
      <ul class="ability-list">
        <template v-for="(ability, index) in Home.abilityList" :key="ability.name">
          <li class="ability-item">
            <nuxt-link :to="ability.path">
              {{ ability.name }}
            </nuxt-link>
          </li>
          <a-divider v-if="index < 3" direction="vertical" />
        </template>
      </ul>
      <ul class="area-list">
        <li class="area-item" v-for="area in Home.areaList" :key="area.desc">
          <div class="num">{{ area.num }}</div>
          <div class="desc">{{ area.desc }}</div>
        </li>
      </ul>
      <div class="country">
        <div class="country-desc">{{ $t('获取全球国家工具包') }}</div>
        <div class="icon-wrapper">
          <div
            class="img"
            v-for="countryIcon in countryFlagList"
            :key="countryIcon"
            :style="{ backgroundImage: `url(${countryIcon})` }"
          ></div>
        </div>
        <div class="select-country-wrapper">
          <CountrySelectNoCreate v-model:targetCountry="targetCountry" ref="countrySelectNoCreateM" />
          <a-button class="btn" type="primary" @click="handleToCountry">
            {{ $t('点击获取') }}
            <img src="@/assets/icons/arrow.svg" class="icon" alt="全球雇佣" />
          </a-button>
        </div>
        <div class="hot-country-list">
          <div class="hot-title">{{ $t('热门国家') }}：</div>
          <template v-for="(hotC, i) in hotCountryList" :key="hotC.id">
            <nuxt-link class="country-name" :to="`/resource/global/${hotC.name}`">{{
              locale === 'zh-CN' ? hotC.cnName : hotC.name
            }}</nuxt-link>
            <a-divider v-if="i < hotCountryList.length - 1" direction="vertical" />
          </template>
        </div>
      </div>
      <HotList class="hot-list" />
    </div>
  </div>
  <div class="home_container">
    <div class="inner-container">
      <div class="data-display">
        <div style="max-width: 600px">
          <div class="title">
            {{ $t('一站式') }}<span style="color: #2e6dff">{{ $t('全球雇佣') }} </span>{{ $t('平台标题') }}
          </div>
          <ul class="ability-list">
            <template v-for="(ability, index) in Home.abilityList" :key="ability.name">
              <li class="ability-item">
                <nuxt-link :to="ability.path">
                  {{ $t(ability.name) }}
                </nuxt-link>
              </li>
              <a-divider v-if="index < 3" direction="vertical" />
            </template>
          </ul>
          <ul class="area-list">
            <li class="area-item" v-for="area in Home.areaList" :key="area.desc">
              <div class="num">{{ area.num }}</div>
              <div class="desc">{{ $t(area.desc) }}</div>
            </li>
          </ul>
          <div class="country">
            <CountrySelectNoCreate v-model:targetCountry="targetCountry" ref="countrySelectNoCreate" />
            <a-button class="btn" type="primary" @click="handleToCountry">
              {{ $t('获取国家工具包') }}
              <img src="@/assets/icons/arrow.svg" class="icon" alt="全球雇佣" />
            </a-button>
          </div>
          <div class="hot-country-list">
            <div class="hot-title">{{ $t('热门国家') }}：</div>
            <template v-for="(hotC, i) in hotCountryList" :key="hotC.id">
              <nuxt-link class="country-name" :to="`/resource/global/${hotC.name}`">{{
                locale === 'zh-CN' ? hotC.cnName : hotC.name
              }}</nuxt-link>
              <a-divider v-if="i < hotCountryList.length - 1" direction="vertical" />
            </template>
          </div>
        </div>
        <HotList v-if="locale === 'zh-CN'" class="hot-list" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CountrySelectNoCreate from '@/components/CountrySelectNoCreate.vue';
import HotList from './hot-list/index.vue';
import Home from '@/assets/contents/home.json';
import { ICountry } from '~~/@types/entities';
import { isMobile } from '@/common/utils';
import '@arco-design/web-vue/es/message/style/css.js';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

type IHotCountryList = ICountry & { id: string; priority: number };

const router = useRouter();
const mobile = ref(false);
const targetCountry = ref('');
const timer = ref<any>(undefined);
const countryList = inject<() => IHotCountryList[]>('countryList');
onMounted(() => {
  mobile.value = isMobile();
});

const { data: allCountryList } = await useAsyncData(async () => {
  const { data } = await useFetch<ICountry[]>('/api/resource/country/list', {
    method: 'GET',
  });
  return data.value;
});

const countrySort = computed(() => {
  if (!countryList) {
    return [];
  }
  return countryList()?.sort((a, b) => {
    return b.priority - a.priority;
  });
});

const countryFlagList = computed(() => {
  return (
    ['se', 'my', 'mx', 'us'].map(
      c => `https://jkqj-assets-beijing.oss-cn-beijing.aliyuncs.com/global/flags_w320/${c}.jpg`
    ) || []
  );
});

const hotCountryList = computed(() => {
  return [
    {
      name: 'singapore',
      id: '8b05de309e810b927b09ba54f9a2dbab',
      cnName: '新加坡',
    },
    {
      name: 'malaysia',
      id: 'e739dd1f6fd0c348521590af1c419854',
      cnName: '马来西亚',
    },
    {
      name: 'mexico',
      id: '8a5512c8fc476c01a89f4cf71f09f2bc',
      cnName: '墨西哥',
    },
    {
      name: 'united-states',
      id: '5a11d8e5e64ad31299eb58d3cac59581',
      cnName: '美国',
    },
    {
      name: 'germany',
      id: '41f90bae949792889e9b2742b282f788',
      cnName: '德国',
    },
  ];
});

const countrySelectNoCreateM = ref<typeof CountrySelectNoCreate | null>(null);
const countrySelectNoCreate = ref<typeof CountrySelectNoCreate | null>(null);
const handleToCountry = () => {
  if (!targetCountry.value) {
    let targetName = '';
    if (mobile.value) {
      targetName = countrySelectNoCreateM.value?.getCurrentCarouselCnName();
    } else {
      targetName = countrySelectNoCreate.value?.getCurrentCarouselCnName();
    }
    const res = countryList?.()?.find(c => c.cnName === targetName);
    if (res) {
      router.push(`/resource/global/${res.name}`);
      return;
    }
  }
  const res = countryList?.()?.find(c => c.cnName === targetCountry.value || c.enName === targetCountry.value);
  if (targetCountry.value && res) {
    router.push(`/resource/global/${res.name}`);
    return;
  }
  const hasCountry = allCountryList.value?.find(c => c.cnName === targetCountry.value);
  if (hasCountry) {
    useLeadsInfo('首页-获取国家工具包', 'contactUs', { targetCountry: targetCountry.value });
    return;
  }
  navigateTo(`/help-center/search?keyword=${targetCountry.value}`);
};

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<style lang="less" scoped>
:deep(.arco-divider-vertical) {
  border-color: #86909c;
}

@media screen and(min-width:800px) {
  .home-container-m {
    display: none;
  }

  .home_container {
    background: url('https://video.reta-inc.com/image/default/251E9797888646CEBEE7CD5E46035E27-6-2.png') no-repeat
      center center/cover;
    // height: 480px;
    padding-bottom: 36px;

    .inner-container {
      width: 1280px;
      // overflow: hidden;
      margin: 0 auto;
      padding: 0 32px;

      .data-display {
        display: flex;

        .hot-list {
          margin: 80px 0 0 200px;
        }
      }

      .title {
        margin: 68px 0 0;
        font-weight: 700;
        font-size: 44px;
        line-height: 64px;
        color: #1d2129;
        white-space: nowrap;
      }

      .ability-list {
        margin: 16px 0 0;
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;

        .ability-item {
          a {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #4e5969;
            text-decoration: none;
          }
        }

        :deep(.arco-divider-vertical) {
          height: 20px;
          margin: 0 16px;
        }
      }

      .area-list {
        margin: 30px 0 0;
        display: flex;
        align-items: center;
        padding: 0;
        list-style: none;
        // width: 414px;
        justify-content: space-between;

        .area-item {
          text-align: left;

          .num {
            font-weight: 600;
            font-size: 30px;
            line-height: 41px;
            color: #1d2129;
          }

          .desc {
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #6e7282;
            margin-top: 12px;
            white-space: nowrap;
          }
        }
      }

      .country {
        margin-top: 66px;
        // width: 416px;
        width: 100%;
        height: 60px;
        background: #ffffff;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        :deep(.arco-input-wrapper) {
          height: 60px;
          background-color: #fff;
          padding-right: 200px;
          border-radius: 4px;

          .arco-select-view-suffix {
            display: none;
          }

          .arco-select-view-input,
          .arco-input {
            font-size: 20px;
          }
        }

        .btn {
          position: absolute;
          // width: 162px;
          height: 44px;
          right: 8px;
          top: 8px;
          box-shadow: 0px 4px 20px rgba(22, 93, 255, 0.25);
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;

          .icon {
            width: 24px;
            height: 24px;
            margin-left: 4px;
          }
        }
      }

      .hot-country-list {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .hot-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #4e5969;
          margin-right: 14px;
        }

        .country-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #4e5969;
          text-decoration: none;
          text-transform: capitalize;
        }

        :deep(.arco-divider-vertical) {
          height: 10px;
          margin: 0 9px;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
@media screen and(max-width:800px) {
  .home_container {
    display: none;
  }

  .tool-list-wrapper {
    display: none;
  }

  .home-container-m {
    width: 100%;
    min-height: 450px;
    background: #fff url('https://video.reta-inc.com/image/default/BA7C68A79CEC4E1886C0FBA379ADE7A5-6-2.png') no-repeat
      top center/100% auto;

    .inner-home-container {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hot-list {
        margin-top: 32px;
      }

      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
        color: #1d2129;
        margin: 32px 0 0;
      }

      .ability-list {
        margin: 12px 0 0;
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;

        .ability-item {
          a {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #4e5969;
            text-decoration: none;
          }
        }

        :deep(.arco-divider-vertical) {
          height: 12px;
          margin: 0 8px;
        }
      }

      .area-list {
        margin: 14px 0 0;
        display: flex;
        align-items: center;
        padding: 0;
        list-style: none;
        justify-content: space-between;
        gap: 16px;

        .area-item {
          text-align: center;

          .num {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #1d2129;
          }

          .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #6e7282;
            margin-top: 6px;
          }
        }
      }

      .country {
        margin-top: 26px;
        width: 100%;
        min-height: 180px;
        background: linear-gradient(179.99deg, #e4faff -3.21%, #ffffff 25.54%);
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        padding: 24px 20px;
        box-sizing: border-box;
        overflow: hidden;

        .country-desc {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #1d2129;
          text-align: center;
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          margin-top: 4px;
          height: 18px;

          .img {
            width: 18px;
            height: 18px;
            background: no-repeat center center/cover;
            border-radius: 50%;
          }
        }

        .select-country-wrapper {
          width: 100%;
          height: 38px;
          background: #ffffff;
          border-radius: 4px;
          margin-top: 16px;
          position: relative;
          border: 1px solid #d6dce7;

          :deep(.arco-input-wrapper) {
            height: 38px;
            background-color: #fff;
            padding-right: 95px;
            border-radius: 4px;

            .arco-select-view-suffix {
              display: none;
            }

            .arco-select-view-input,
            .arco-input {
              font-size: 16px;
            }
          }

          .btn {
            position: absolute;
            width: 95px;
            height: 40px;
            right: -1px;
            top: -1px;
            border-radius: 0px 4px 4px 0px;
            font-weight: 500;
            font-size: 14px;

            .icon {
              width: 20px;
              height: 20px;
              margin-left: 4px;
            }
          }
        }

        .hot-country-list {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 16px;
          gap: 8px;

          .hot-title,
          .country-name {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            color: #4e5969;
            text-decoration: none;
          }

          :deep(.arco-divider-vertical) {
            height: 8px;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
